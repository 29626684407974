import { type ForcedState, type FormControllerAPI } from '@wix/form-viewer';
import { FormKind, initFormController } from '@wix/form-viewer/controller';
import { createEventHandler } from '@wix/tpa-settings';
import {
  type ControllerFlowAPI,
  type ControllerParams,
  type CreateControllerFn,
} from '@wix/yoshi-flow-editor';
import { EXPERIMENTS } from '../../constants/experiments';
import { NAMESPACE } from '../../constants/namespace';
import {
  SettingsEventsKeys,
  type SettingsEvents,
} from '../../constants/settings';
import { FORM_TEMPLATES, FormAppPreset } from '../../constants/templates';
import settingsParams from './settingsParams';
import { createFireSubmitTrackEvent } from './utils/submission-track-event';
import { isTemplateForm } from './utils/templates';
import { createVeloApi } from './velo-api';
import { demoForm } from './demo-form';

type ControllerConfig = ControllerParams['controllerConfig'];

const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig,
}) => {
  let formControllerApi: FormControllerAPI;
  const { veloProps, veloApi, veloSetSteps } = createVeloApi(controllerConfig);
  const componentEventHandler = createEventHandler<SettingsEvents>(
    controllerConfig.config.publicData.COMPONENT || {},
  );

  const multilineAddressEnabled = flowAPI.experiments.enabled(
    EXPERIMENTS.ENABLE_MULTILINE_ADDRESS,
  );
  const controllerState = {
    formId: flowAPI.settings.get(settingsParams.formId) as string,
  };
  const presetId = flowAPI.settings.get(settingsParams.presetId) as string;

  if (controllerState.formId) {
    formControllerApi = await initFormController(flowAPI, {
      formId: controllerState.formId,
      formKind: isTemplateForm(controllerState.formId)
        ? FormKind.EXTENSION
        : undefined,
      namespace: NAMESPACE,
      throwWhenFormMissing: false,
      enableMultilineAddress: multilineAddressEnabled,
    });

    veloSetSteps(formControllerApi.getFormSteps(controllerState.formId) || []);
  }

  return {
    pageReady: async () => {
      const formId = controllerState.formId;
      const isInEditor =
        flowAPI.environment.isEditor ||
        flowAPI.environment.isClassicEditor ||
        flowAPI.environment.isEditorX;

      const isFormIdMissing =
        presetId !== FormAppPreset.Blank &&
        presetId !== FormAppPreset.Existing &&
        !formId;

      if (isFormIdMissing && isInEditor) {
        controllerConfig.setProps({
          fitToContentHeight: true,
          loading: true,
        });
        return;
      }

      const showDemoFormEnabled = flowAPI.experiments.enabled(
        EXPERIMENTS.SHOW_DEMO_FORM,
      );

      if (!controllerState.formId && showDemoFormEnabled) {
        controllerConfig.setProps({
          loading: false,
          formId: 'demo',
          wfConfig: {
            formId: 'demo',
            formsById: {
              demo: demoForm,
            },
          },
          wfActions: {},
          wfResults: [],
        });
      }

      controllerConfig.setProps({
        fitToContentHeight: true,
        loading: true,
      });

      try {
        const showEmptyState = await getShowEmptyState(
          formId,
          flowAPI,
          controllerConfig,
        );

        if (showEmptyState) {
          controllerConfig.setProps({
            fitToContentHeight: true,
            loading: false,
          });
        } else {
          if (formId && formControllerApi) {
            if (formControllerApi.hasForm(formId)) {
              controllerConfig.setProps({
                formId,
                loading: false,
                fireSubmitTrackEvent: createFireSubmitTrackEvent(
                  formId,
                  formControllerApi.getFormName(formId),
                  formControllerApi.getFieldPropertiesByTarget(formId) || {},
                  controllerConfig,
                ),
                isFormDisabled: formControllerApi.isFormDisabled(formId),
                ...veloProps,
              });
            } else {
              controllerConfig.setProps({
                loading: false,
                formDeleted: true,
              });
            }
          }
        }
      } catch (e) {
        // if error was encountered, set loading false, widget should show empty state
        console.error(e);
        controllerConfig.setProps({
          fitToContentHeight: true,
          loading: false,
        });
      }

      componentEventHandler.on(
        SettingsEventsKeys.ForceView,
        (forceView: ForcedState) =>
          controllerConfig.setProps({
            forceView,
          }),
      );
    },
    updateConfig: async (_, updatedConfig) => {
      const updatedFormId = updatedConfig?.publicData?.COMPONENT?.formId;
      const templateForm = isTemplateForm(updatedFormId);

      componentEventHandler.notify(updatedConfig.publicData.COMPONENT || {});

      if (!updatedFormId) {
        return;
      }

      if (controllerState.formId !== updatedFormId) {
        controllerConfig.setProps({
          fitToContentHeight: true,
          loading: true,
        });

        const { getFormSteps, hasForm, isFormDisabled } =
          await initFormController(flowAPI, {
            formId: updatedFormId,
            formKind: templateForm ? FormKind.EXTENSION : undefined,
            namespace: NAMESPACE,
          });

        veloSetSteps(getFormSteps(updatedFormId));

        controllerConfig.setProps({
          fitToContentHeight: true,
          formId: updatedFormId,
          loading: false,
          formDeleted: !hasForm(updatedFormId),
          isFormDisabled: isFormDisabled(updatedFormId),
        });

        controllerState.formId = updatedFormId;
      }
    },
    exports: () => veloApi,
  };
};

async function getShowEmptyState(
  formId: string,
  flowAPI: ControllerFlowAPI,
  controllerConfig: ControllerConfig,
) {
  const shouldCheckIfFormLimitReached = !(
    flowAPI.environment.isViewer || flowAPI.environment.isPreview
  );

  const formLimitReached = shouldCheckIfFormLimitReached
    ? await import('./form-limit').then(({ isFormsLimitReached }) =>
        isFormsLimitReached({
          httpClient: flowAPI.httpClient,
          errorHandler: flowAPI.errorHandler,
        }),
      )
    : false;

  const presetId = controllerConfig.config.publicData.COMPONENT.presetId;
  const newlyAddedTemplateForm =
    Object.keys(FORM_TEMPLATES).includes(presetId) && formId === '';

  const shouldShowEmptyState =
    ([FormAppPreset.Blank, FormAppPreset.Existing, undefined].includes(
      presetId,
    ) &&
      formId === '') ||
    (newlyAddedTemplateForm && formLimitReached);

  return shouldShowEmptyState;
}

export default createController;
